import React, { Component } from 'react';
import styled from '@emotion/styled';
import Input from './Input.js';
import Arrow from '../../SVG/Arrow.js';
import GrebbanLogo from '../../SVG/GrebbanLogo.js';
import { signup } from '../../../lib/api';

const breakpoints = [576, 768, 885, 1280, 1800, 2000];

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  position: relative;
  top: 0;
  overflow-x: hidden;

  ${mq[3]} {
    position: fixed;
    width: 50%;
  }
`;

const InputHeadline = styled.p`
  height: 20px;
  width: 212px;
  color: #FFFFFF;
  font-family: "Circular";
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
  margin-top: 233px;

  ${mq[3]} {
    margin-top: 0;
  }
`;

const InputWrapper = styled.div`
  height: 50px;
  position: relative;
`;

const LogoWrapper = styled.div`
  width: 400px;
  height: 103px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 160px;
  position: relative;
  bottom: 0;
  margin-bottom: 40px;

  ${mq[2]} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${mq[3]} {
    position: fixed;
  }

  p {
    color: #FFFFFF;
    font-family: "Helvetica Neue";
    font-size: 14px;
    letter-spacing: 0.03px;
    line-height: 16px;
  }

  .cross {
    font-family: "Circular";
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }

  .space-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 234px;
    height: 42px;
  }

  .centered {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
`;

const DotSureLogo = styled.img`
  height: 42px;
  width: 50px;
`;

const Completed = styled.div`
  height: ${props => props.completed ? '100%' : '0px'};
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FCEBE3;
  position: ${props => props.completed ? 'fixed' : 'fixed'};
  top: ${props => props.completed ? '0' : '100%'};
  z-index: 100;
  visibility: ${props => props.completed ? 'visible' : 'hidden'};
  transition: top 1s ease-in-out;
  overflow: hidden;

  ${mq[3]} {
    width: 50vw;
  }
`;

const ThankYouMessage = styled.h1`
  font-family: "Circular";
  font-weight: 300;
  margin: 0;
  font-size: 46px;
  letter-spacing: 0.13px;
  line-height: 62px;
  position: absolute;
  top: 35%;
  opacity: ${props => props.completed ? '1' : '0'};
  width: auto;
  transform: translateX(${props => props.completed ? '0%' : '100%'});
  transition: all .5s ease-in-out .8s;

  ${mq[1]} {
    font-size: 96px;
    letter-spacing: 0.22px;
    line-height: 96px;
    width: 545px;
  }

  ${mq[3]} {
    top: 338px;
  }
`;

const IncompleteMessage = styled.div`
  height: 20px;
  margin-top: 16px;

  p {
    color: #FFFFFF;
    font-family: "Circular";
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    margin: 0;
  }
`;

class Right extends Component {

  state = {
      email: null,
      emailVerified: false,
      inputError: false,
      signupComplete: false,
      emailError: false,
      width: window.innerWidth,
  };

  componentWillMount() {
  window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleEmailChange = e => {
      var regexEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;

      if (regexEmail.test(e.target.value)) {
          this.setState({
              emailVerified: true,
              emailError: false,
          });
      } else {
          this.setState({
              emailVerified: false,
          });
      }

      this.setState({
          email: e.target.value,
          inputError: false,
      });
  };

  handleKeyDown = e => {
    // On RETURN
    if (e.keyCode === 13) {
      this.submitForm();
    }
  };

  submitForm = () => {
    if (this.state.emailVerified) {
        signup(this.state.email).then((result) => {
          if (!result || !result.success) {
            alert('Det har inträffat ett fel, vänligen försök igen.')
          }
        });
        this.setState({
            inputError: false,
            signupComplete: true,
        });
    } else if (!this.state.emailVerified) {
        this.setState({
            emailError: true,
        });
    }

  }

  render() {
    const { width } = this.state;
    const isSmall = width <= 885;

    const latestDate = 'Anmäl dig här! Senast 1 mars.';
    const thankYouLine1 = 'Kul!';
    const thankYouLine2 = 'Vi ses den';
    const thankYouLine3 = '12 mars 8:30';
    const partnerLogo = '/assets/dot_sure_logo.png';

    if (isSmall) {
      return (
        <Wrapper>
            <InputHeadline>{latestDate}</InputHeadline>
            <InputWrapper>
              <Input placeholder="mail@mail.com" onChange={this.handleEmailChange} required onKeyDown={this.handleKeyDown} />
              <div onClick={this.submitForm}>
                  <Arrow
                    height="20px"
                    width="18.13px"
                    color="black"
                    position="absolute"
                    rotation="-90deg"
                    top="30%"
                    bottom="0"
                    right={this.state.signupComplete ? '-200px' : '16px'}
                    cursor="pointer"
                  />
              </div>
            </InputWrapper>
            <IncompleteMessage>
              {this.state.emailError && !this.state.emailVerified && (
                <p>Vänligen ange en giltig mailadress</p>
              )}
            </IncompleteMessage>
            <LogoWrapper>
              <div className="centered">
                <p>Ett samarbete mellan: </p>
              </div>
              <div className="space-between">
                <GrebbanLogo height="20px" color="white"></GrebbanLogo>
                <p className="cross">+</p>
              <DotSureLogo src={partnerLogo} />
              </div>
            </LogoWrapper>

            <Completed completed={this.state.signupComplete}>
              <ThankYouMessage completed={this.state.signupComplete}>{thankYouLine1}<br/>{thankYouLine2}<br/>{thankYouLine3}</ThankYouMessage>
            </Completed>
        </Wrapper>

      );
    } else {
      return (
        <Wrapper>
            <InputHeadline>{latestDate}</InputHeadline>
            <InputWrapper>
              <Input type="text" placeholder="mail@mail.com" onChange={this.handleEmailChange} required onKeyDown={this.handleKeyDown} />
            <div onClick={this.submitForm}
                >
                  <Arrow
                    height="20px"
                    width="18.13px"
                    color="black"
                    position="absolute"
                    rotation="-90deg"
                    top="30%"
                    bottom="0"
                    right={this.state.signupComplete ? '-200px' : '16px'}
                    cursor="pointer"
                  />
              </div>
            </InputWrapper>
            <IncompleteMessage>
              {this.state.emailError && !this.state.emailVerified && (
                <p>Vänligen ange en giltig mailadress</p>
              )}
            </IncompleteMessage>
            <LogoWrapper>
                <p>Ett samarbete mellan: </p>
                <GrebbanLogo height="20px" color="white"></GrebbanLogo>
                <p className="cross">+</p>
              <DotSureLogo src={partnerLogo} />
            </LogoWrapper>

            <Completed completed={this.state.signupComplete}>
              <ThankYouMessage completed={this.state.signupComplete}>{thankYouLine1}<br/>{thankYouLine2}<br/>{thankYouLine3}</ThankYouMessage>
            </Completed>
        </Wrapper>
      );
    }
  }
}

export default Right;
