import React, { Component } from 'react';
import "./App.css";

import Frontpage from './components/Frontpage';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Frontpage />
      </div>
    );
  }
}

export default App;
