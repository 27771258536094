import React, { Component } from 'react';
import styled from '@emotion/styled';
import Circle from '../../SVG/Circle.js';
import CircleMobile from '../../SVG/CircleMobile.js';
import Arrow from '../../SVG/Arrow.js';

const breakpoints = [576, 566, 885, 1280, 1800, 2000];

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  padding-left: 5%;
  padding-top: 38px;
  padding-right: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq[2]} {
    padding-left: 68px;
    padding-top: 38px;
    padding-right: 36px;
  }

  ${mq[3]} {
    padding-left: 45px;
    padding-top: 38px;
    padding-right: 36px;
  }
`;

const Container = styled.div`
  width: 100%;
  min-width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FFFFFF;
  position: relative;

  ${mq[2]} {
    min-width: 801px;
    width: 60%;
  }

  ${mq[3]} {
    width: 100%;
    min-width: auto;
  }

  ${mq[4]} {
    width: 788px;
  }
`;

const TopDiv = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  p {
    margin: 0;
    border-bottom: 2px solid black;
    height: 18px;
    font-family: 'Circular';
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 18px;
    font-size: 14px;
  }
`;

const Headline = styled.h1`
  width: 100%;
  height: 115px;
  font-family: "Circular";
  font-weight: 300;
  margin: 0;
  font-size: 56px;
  letter-spacing: 0.13px;
  line-height: 62px;

  ${mq[1]} {
    font-size: 96px;
    letter-spacing: 0.22px;
    line-height: 96px;
    height: 176px;
  }
`;

const CircleWrapper = styled.div`
  height: 120px;
  width: 120px;
  animation: spin 35s linear infinite;
  will-change: transform;

  ${mq[1]} {
    height: 150px;
    width: 150px;
  }

  ${mq[2]} {
    height: 150px;
    width: 150px;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: column;

  ${mq[2]} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }

  ${mq[3]} {
    width: 90%;
    height: 50vw;
    max-height: 788px;
    flex-direction: row;
  }

  ${mq[4]} {
    width: 709px;
    height: 772px;
    flex-direction: row;
  }
`;

const Column = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq[1]} {
    width: 80%;
  }

  ${mq[2]} {
    height: 746px;
  }

  ${mq[3]} {
    width: 45%;
    height: 100%;
  }

  h2 {
    font-family: "Circular";
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
  }

  p {
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.03px;
    line-height: 20px;
  }

  .ImageText {
    font-family: "Helvetica Neue";
    line-height: 20px;
    font-size: 12px;
    letter-spacing: 0.03px;
    margin-top: 8px;
    font-weight: 300;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 48px;

  ${mq[2]} {
    width: 360px;
    height: 685px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  ${mq[3]} {
    width: 45%;
    height: 100%;
  }

  .ImageText {
    font-family: "Helvetica Neue";
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0.02px;
    line-height: 20px;
    margin-top: 4px;

    ${mq[1]} {
      font-size: 12px;
      letter-spacing: 0.03px;
      margin-top: 8px;
      font-weight: 300;
    }
  }

  p {
    font-family: "Helvetica Neue";
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.03px;
    line-height: 20px;
  }
`;

const TextContainer = styled.div`
  width: 80%;

  ${mq[3]} {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;

  ${mq[1]} {
    width: 80%;
  }

  ${mq[3]} {
    width: 100%;
    height: auto;
  }

  ${mq[4]} {
    height: 478px;
  }
`;

const ScrollButton = styled.div`
  transform: rotate(-90deg);
  position: absolute;
  right: -40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${mq[3]} {
    right: -30px;
  }

  &.toSpeakers {
    bottom: 350px;
    ${mq[3]} {
      bottom: 700px;
    }

  }

  &.toSignup {
    bottom: 700px;
  }

  p {
    font-family: "Circular";
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    margin: 0 0 0 10px;
    text-align: center;
  }
`;

class Left extends Component {

  state = {
    width: window.innerWidth,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      width: window.innerWidth
    })
  };

  scrollToSpeakers = () => {
    this.speakersRef.scrollIntoView({
      behavior: 'smooth'
    });
  }

  scrollToSignup = () => {
    window.scroll({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  render() {

    const { width } = this.state;
    const isSmall = width <= 885;
    const isMedium = width <= 1279;
    const isAboveMedium = width >= 1279;

    const headline = 'Grebbtalks';
    const seminar = 'Frukostseminarium 001';
    const subject = 'Facebook-annonsering 2019';
    const subjectContent = 'Vi kommer prata om de senaste trenderna, tricksen och smartaste strategierna på Facebook 2019. Dessutom kommer vi prata specifikt om ett par spännande case och hur de lyckats, inte minst Facebooksuccén Flattered. Vi bjuder på frukost på Grebbans kontor i centrala Göteborg.';
    const forWho = 'För vem?';
    const forWhoContent = 'Inbjudan är personlig. Vi bjuder in ett tjugotal e-handelschefer, marknadschefer och andra spännande personer som alla på något sätt arbetar med kundanskaffning digitalt. Välkommen!';
    const time = '12 mars 8:30-10:00';
    const at = '@ Grebban';
    const address = 'Viktoriagatan 16, Göteborg';
    const speaker1 = 'Anton Johansson, VD på Grebban';
    const speaker2 = 'Emma Lythell, VD på Dot Sure';
    const speaker1_image = '/assets/anton.jpg';
    const speaker2_image = '/assets/emma.jpg';

    if (isSmall) {
      return (
        <Wrapper>
          <Container>
            <TopDiv>
              <p>{seminar}</p>
              <CircleWrapper>
                <CircleMobile height="100%" width="100%" color="black" />
              </CircleWrapper>
            </TopDiv>

            <Headline>
              {headline}
            </Headline>

            <ContentWrapper>
              <Column>
                <div style={{marginBottom: '30px'}}>
                  <h2>{subject}</h2>
                  <p>{subjectContent}</p>
                </div>

                <div>
                  <h2>{forWho}</h2>
                  <p style={{marginBottom: '24px'}}>{forWhoContent}</p>
                  <h2>{time}<br/>{at}<br/>{address}</h2>
                </div>
              </Column>

              <Row>
                <div style={{marginRight: '10px'}} ref={ref => (this.speakersRef = ref)}>
                  <Image src={speaker1_image} alt="" />
                  <p className="ImageText">{speaker1}</p>
                </div>
                <div>
                  <Image src={speaker2_image} alt="" />
                  <p className="ImageText">{speaker2}</p>
                </div>
              </Row>
            </ContentWrapper>

            <ScrollButton className="toSignup" onClick={this.scrollToSignup}>
              <Arrow
                height="20px"
                width="18.13px"
                color="black"
                rotation="90deg"
              />
              <p>Anmälan</p>
            </ScrollButton>
          </Container>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper>
          <Container>
            <TopDiv>
              <p>{seminar}</p>
              <CircleWrapper>
                <Circle height="100%" width="100%" color="black" />
              </CircleWrapper>
            </TopDiv>

            <Headline>
              {headline}
            </Headline>

            <ContentWrapper>
              <Column>
                <TextContainer>
                  <h2>{subject}</h2>
                  <p>
                    {subjectContent}
                  </p>
                </TextContainer>

                <div>
                  <Image src={speaker1_image} alt="" />
                <p className="ImageText">{speaker1}</p>
                </div>
              </Column>

              <Column>
                <TextContainer>
                  <h2>{forWho}</h2>
                  <p>
                    {forWhoContent}
                  </p>
                  <h2>{time}<br/>{at}<br/>{address}</h2>
                </TextContainer>

                <div ref={ref => (this.speakersRef = ref)}>
                  <Image src={speaker2_image} alt="" />
                <p className="ImageText">{speaker2}</p>
                </div>
              </Column>
            </ContentWrapper>

            {isAboveMedium && (
              <ScrollButton className="toSpeakers" onClick={this.scrollToSpeakers}>
                <Arrow
                  height="20px"
                  width="18.13px"
                  color="black"
                  rotation="90deg"
                />
                <p>Speakers</p>
              </ScrollButton>
            )}

            {isMedium && (
              <ScrollButton className="toSignup" onClick={this.scrollToSignup}>
                <Arrow
                  height="20px"
                  width="18.13px"
                  color="black"
                  rotation="90deg"
                />
                <p>Anmälan</p>
              </ScrollButton>
            )}
          </Container>
        </Wrapper>
      );
    }
  }
}

export default Left;
