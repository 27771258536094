import React, { Component } from "react";
import styled from '@emotion/styled';

const StyledSVG = styled.svg`
  height: ${props => props.height};
  width: ${props => props.width};
  position: ${props => props.position};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  transform: rotate(${props => props.rotation});
  cursor: ${props => props.cursor};
  transition: right 1.5s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  polygon {
    fill: ${props => props.color};
  }
`;

class Arrow extends Component {

  render() {
    return (
      <StyledSVG
        version="1.1" x="0px" y="0px"
        viewBox="0 0 12 18"
        height={this.props.height}
        width={this.props.width}
        color={this.props.color}
        rotation={this.props.rotation}
        position={this.props.position}
        right={this.props.right}
        top={this.props.top}
        bottom={this.props.bottom}
        cursor={this.props.cursor}
        >
          <polygon points="678.116 772 684 777.987 678.116 784 676.999 782.858 680.822 778.926 666 778.926 666 777.074 680.847 777.074 676.999 773.167" transform="rotate(90 725 59)"/>
      </StyledSVG>

    );
  }
}


export default Arrow;
