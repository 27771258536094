import React, { Component } from 'react';
import styled from '@emotion/styled';
import Left from './Left';
import Right from './Right';

const breakpoints = [576, 768, 885, 1280, 1800, 2000];

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mq[3]} {
    flex-direction: row;
    height: 100vh;
  }
`;

const Wrap = styled.div`
  width: 100vw;
  height: auto;

  ${mq[3]} {
    width: 50vw;
    height: 100vh;
  }
`;

class Frontpage extends Component {
  render() {
    return (
      <Wrapper>
        <Wrap>
          <Left />
        </Wrap>
        <Wrap>
          <Right />
        </Wrap>
      </Wrapper>

    );
  }
}

export default Frontpage;
