import React, { Component } from "react";
import styled from '@emotion/styled';

const StyledSVG = styled.svg`
  height: ${props => props.height};
  width: ${props => props.width};

  path {
    fill: ${props => props.color};
  }
  polyline {
    fill: ${props => props.color};
  }
`;

class GrebbanLogo extends Component {

  render() {
    return (
      <StyledSVG
        version="1.1" x="0px" y="0px"
        viewBox="0 0 126 20"
        height={this.props.height}
        width={this.props.width}
        color={this.props.color}
        >
            <path d="M9.74101934,19.2 C3.96962434,19.2 0,15.1508267 0,9.67258667 L0,9.61792 C0,4.35226667 4.10153798,0.0380266667 9.71365513,0.0380266667 C13.0495819,0.0380266667 15.0601847,0.938826667 16.9923221,2.57957333 L14.4264339,5.67621333 C12.9948535,4.48304 11.7252714,3.79664 9.58083468,3.79664 C6.61701849,3.79664 4.25969565,6.41685333 4.25969565,9.56714667 L4.25969565,9.61792 C4.25969565,13.0060267 6.58976096,15.49456 9.8718128,15.49456 C11.3552411,15.49456 12.6774713,15.12352 13.7105903,14.38256 L13.7105903,11.7357333 L9.6081989,11.7357333 L9.6081989,8.2168 L17.6548774,8.2168 L17.6548774,16.2619733 C15.748664,17.876 13.1276206,19.2 9.74101934,19.2" mask="url(#b)"/>
            <path d="M31.4183022,6.68311804 C31.4183022,4.94820747 30.1992375,4.05405474 28.2073305,4.05405474 L24.1478094,4.05405474 L24.1478094,9.33633618 L28.287689,9.33633618 C30.276602,9.33633618 31.4183022,8.28613049 31.4183022,6.73349359 L31.4183022,6.68311804 Z M31.3385318,18.8 L27.3592623,12.9123115 L24.1478094,12.9123115 L24.1478094,18.8 L20.0636943,18.8 L20.0636943,0.4 L28.5528774,0.4 C32.9306246,0.4 35.5572729,2.68676384 35.5572729,6.47165728 L35.5572729,6.5240987 C35.5572729,9.49440205 33.9392494,11.3612637 31.5784311,12.2290898 L36.1146497,18.8 L31.3385318,18.8 Z"/>
            <polyline points="37.72 18.8 37.72 .4 52.03 .4 52.03 4.002 41.866 4.002 41.866 7.733 50.81 7.733 50.81 11.334 41.866 11.334 41.866 15.198 52.166 15.198 52.166 18.8 37.72 18.8"/>
            <path d="M66.4964891 13.2008982C66.4964891 11.9653465 65.552763 11.2026681 63.4201375 11.2026681L58.61942 11.2026681 58.61942 15.2525762 63.5560428 15.2525762C65.3896766 15.2525762 66.4964891 14.6221137 66.4964891 13.2551936L66.4964891 13.2008982zM65.445126 5.84130079C65.445126 4.6310693 64.4736752 3.9488011 62.71941 3.9488011L58.61942 3.9488011 58.61942 7.83905412 62.4508612 7.83905412C64.284495 7.83905412 65.445126 7.2601326 65.445126 5.89374221L65.445126 5.84130079zM63.5560428 18.8L54.5732484 18.8 54.5732484.4 63.3402252.4C67.1983039.4 69.5722971 2.26633195 69.5722971 5.15765534L69.5722971 5.21099727C69.5722971 7.28725382 68.4393908 8.44324287 67.0906669 9.18023025 69.2743927 9.99386686 70.6242038 11.2298953 70.6242038 13.7002571L70.6242038 13.7546055C70.6242038 17.1193848 67.8180319 18.8 63.5560428 18.8zM84.1533632 13.2008982C84.1533632 11.9653465 83.209701 11.2026681 81.07722 11.2026681L76.276284 11.2026681 76.276284 15.2525762 81.2125725 15.2525762C83.0466257 15.2525762 84.1533632 14.6221137 84.1533632 13.2551936L84.1533632 13.2008982zM83.1004406 5.84130079C83.1004406 4.6310693 82.1301427 3.9488011 80.3754528 3.9488011L76.276284 3.9488011 76.276284 7.83905412 80.105835 7.83905412C81.9409754 7.83905412 83.1004406 7.2601326 83.1004406 5.89374221L83.1004406 5.84130079zM81.2125725 18.8L72.2292994 18.8 72.2292994.4 80.9967696.4C84.8545869.4 87.2273321 2.26633195 87.2273321 5.15765534L87.2273321 5.21099727C87.2273321 7.28725382 86.0945026 8.44324287 84.7453265 9.18023025 86.9305351 9.99386686 88.2802548 11.2298953 88.2802548 13.7002571L88.2802548 13.7546055C88.2802548 17.1193848 85.4748165 18.8 81.2125725 18.8zM98.2576106 5.22773319L95.7433823 11.1527406 100.772384 11.1527406 98.2576106 5.22773319zM103.992274 18.8L102.260541 14.7013286 94.2552251 14.7013286 92.5251265 18.8 88.2802548 18.8 96.3929186.4 100.233465.4 108.343949 18.8 103.992274 18.8z"/>
            <polyline points="122.448 18.8 113.264 7.103 113.264 18.8 109.146 18.8 109.146 .4 112.992 .4 121.88 11.73 121.88 .4 126 .4 126 18.8 122.448 18.8"/>
      </StyledSVG>

    );
  }
}


export default GrebbanLogo;
