import React from 'react';
import styled from '@emotion/styled';

const breakpoints = [576, 768, 885, 1440, 1800, 2000];

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const placeholderStyle = 'font-size: 16px; line-height: 18px; font-family: "Helvetica Neue";';
const InputField = styled.input`
    background-color: #fff;
    color: #222222;
    outline: none;
    border: none;
    padding: 0px 40px 0px 16px;
    height: 50px;
    width: 200px;
    box-shadow: inset 0 1px 3px 0 #ECEAE9;
    border-radius: 8px;
    font-family: "Helvetica Neue";
    font-size: 16px;
    letter-spacing: 0.35px;
    line-height: 18px;
    z-index: 1000;

    ${mq[1]} {
      width: 246px;
    }

    &::-webkit-input-placeholder {
        ${placeholderStyle};
    }
    &::-moz-placeholder {
        ${placeholderStyle};
    }
    &:-moz-placeholder {
        ${placeholderStyle};
    }
    &:-ms-input-placeholder {
        ${placeholderStyle};
    }
`;

const Input = props => {
    return <InputField {...props} />;
};

export default Input;
