const SIGNUP_URL = 'https://grebbtalks.se/backend/signup.php';

const postData = (
    url,
    postData = {}
  ) => {
    const data = new URLSearchParams();
    Object.keys(postData).forEach(key => {
        data.append(key, postData[key]);
    });

    const init = {
        method: 'POST',
        body: data,    
    };
  
    return fetch(url, init).then(response => {
      if (!response.ok) {
          console.log(response);
          return false;
      }
  
      var contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      }

      return false;
    });
  };

  export const signup = email => {
    return postData(SIGNUP_URL, { email });
  };